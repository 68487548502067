<div class="header-topnav ">
    <div style="background: #3084e9;padding: 8px 0px;">
        <div class="container">
            <div class="d-flex-spacebt-center" style="padding: 0px 12px;">
                <mat-form-field appearance="none" class="overall-search">
                    <input matInput placeholder="Search Here ..." />
                  <!-- <mat-placeholder class="placeholder">Search Here ...</mat-placeholder> -->
                    <span matSuffix>
                        <mat-icon>search</mat-icon>
                    </span>
                </mat-form-field>
                <div class="">
                    <img src="assets/images/logo.png" alt="" class="app-logo" width="70px" height="70px">
                </div>
                <div>
                    <mat-button-toggle-group class="type" name="fontStyle" aria-label="Font Style">
                        <mat-button-toggle value="1" [checked]="true" [routerLink]="'/dashboard'">Case Type</mat-button-toggle>
                        <!-- <mat-button-toggle value="0" [routerLink]="'/dashboard/court'">Court</mat-button-toggle> -->

                    </mat-button-toggle-group>
                    <button mat-icon-button [matMenuTriggerFor]="accountMenu"
                        class="topbar-button-right mr-1 img-button">
                        <img src="assets/images/profile.png" alt=""
                            style="width: 45px !important;height: 45px !important;">
                    </button>
                    <mat-menu #accountMenu="matMenu">
                        <button mat-menu-item [routerLink]="['/change-password']">
                            <mat-icon>lock</mat-icon>
                            <span>Change Password</span>
                        </button>
                        <!-- <button mat-menu-item *ngIf="portalType === checkType.COMPANY" [routerLink]="['/company/settings/change-password']">
                        <mat-icon>lock</mat-icon>
                        <span>Change Password</span>
                    </button> -->
                        <button mat-menu-item (click)="logout(portalType)">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>Sign out</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>

    <div class="topnav">
        <!-- App Logo -->
        <div class="container">

            <ul class="menu" *ngIf="!layoutConf.isMobile" style="padding: 0px 8px;">
                <li *ngFor="let item of menuItems; let i = index;" class="first-menu">
                    <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
                        <a class="f14 parent-menu" matRipple routerLink="/{{getLink(item)}}"
                            *ngIf="item.type === 'link'">
                            <!-- <mat-icon>{{item.icon}}</mat-icon>  -->
                            <img src="{{item.icon}}" width="20" height="20" class="mr-4" />
                            {{item.name | translate}}
                        </a>
                        <div *ngFor="let s of item.sub" style="display: none;">
                            <a class="f14" matRipple *ngIf="item.name == s.name" routerLink="/{{s.state}}"> {{s.name
                                }}</a>
                        </div>
                        <div *ngIf="item.type === 'dropDown'">
                            <label matRipple for="drop-{{i}}" class="toggle">
                                <!-- <mat-icon>{{item.icon}}</mat-icon> -->

                                {{item.name | translate}}
                            </label>
                            <a class="f14 parent-menu" matRipple style="position: relative;top: 1px;">
                                <!-- <mat-icon>{{item.icon}}</mat-icon>  -->
                                <img src="{{item.icon}}" width="20" height="20" class="mr-4"
                                    *ngIf="item.name != 'More' " />
                                <img src="assets/images/w-more.png" width="20" height="20" class="mr-4"
                                    *ngIf="item.name == 'More' " />
                                {{item.name | translate}}
                            </a>
                            <input type="checkbox" id="drop-{{i}}" />
                            <ul class="sub-menu">
                                <li *ngFor="let itemLvL2 of item.sub; let j = index;" routerLinkActive="open">
                                    <a matRipple (click)="itemLvL2.state == 'settings' ? route(itemLvL2.state) : ''"
                                        routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
                                        *ngIf="itemLvL2.type !== 'dropDown'">
                                        <!-- <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>   -->
                                        {{itemLvL2.name | translate}}
                                    </a>
                                    <!-- <div *ngFor="let hs of itemLvL2.sub" style="display: none;">
                                        <a *ngIf="itemLvL2.name == hs.name" matRipple
                                            routerLink="/{{hs.state}}">{{hs.name}}</a>
                                    </div> -->
                                    <div *ngIf="itemLvL2.type === 'dropDown'">
                                        <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name |
                                            translate}}</label>
                                        <a matRipple>
                                            {{itemLvL2.name | translate}}
                                        </a>
                                        <input type="checkbox" id="drop-{{i}}{{j}}" />
                                        <!-- Level 2 -->
                                        <ul>
                                            <li *ngFor="let itemLvL3 of itemLvL2.sub; let k = index"
                                                routerLinkActive="open"
                                                [ngClass]="itemLvL2.name == 'Reports' ? 'w200' : w270">
                                                <a matRipple *ngIf="itemLvL3.type !== 'dropDown'"
                                                    routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                                                    {{itemLvL3.name | translate}}
                                                </a>


                                                <div *ngIf="itemLvL3.type === 'dropDown'">
                                                    <label matRipple for="drop-{{i}}{{j}}{{k}}"
                                                        class="toggle">{{itemLvL3.name |
                                                        translate}}</label>
                                                    <a matRipple>
                                                        {{itemLvL3.name | translate}}
                                                    </a>
                                                    <input type="checkbox" id="drop-{{i}}{{j}}{{k}}" />
                                                    <!-- Level 3 -->
                                                    <ul class="level_3" >
                                                        <li *ngFor="let itemLvL4 of itemLvL3.sub"
                                                            routerLinkActive="open">
                                                            <a matRipple
                                                                routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL3.state ? '/'+itemLvL3.state : ''}}/{{itemLvL4.state}}">
                                                                {{itemLvL4.name | translate}}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
            <span fxFlex></span>
            <!-- End Navigation -->

            <!-- Top left user menu -->
            <!-- <mat-button-toggle-group class="language" name="fontStyle" aria-label="Font Style">
                <mat-button-toggle value="1" (click)="eng();"><img src="assets/images/british.png" width="23"
                        height="23" *ngIf="showEng" /><img src="assets/images/india.png" width="25" height="25"
                        *ngIf="showTam" />
                </mat-button-toggle>
                <mat-button-toggle value="2" (click)="tam();"><span *ngIf="showTam">Tamil</span> <span
                        *ngIf="showEng">English</span>
                </mat-button-toggle>
            </mat-button-toggle-group>
            -->
            <!-- <select class="mr-12 p-4" style="border-radius: 4px;border: 1px solid #8080805c;">
                <option value="1">English</option>
                <option value="2">Tamil</option>
            </select>
            <select class=" p-4" style="border-radius: 4px;border: 1px solid #8080805c;">
                <option value="1">Court</option>
                <option value="2" selected>Case Type</option>
            </select> -->
            <!-- <span class="pr-8">{{userName}}</span> -->

            <!-- Notification toggle button -->
            <!-- <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()" [style.overflow]="'visible'"
        class="topbar-button-right">
        <mat-icon>notifications_none</mat-icon>
        <span class="notification-number mat-bg-success">3</span>
      </button> -->
            <!-- Mobile screen menu toggle -->
            <button mat-icon-button class="mr-1" (click)="toggleSidenav()" *ngIf="layoutConf.isMobile">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </div>
</div>