import { Routes } from "@angular/router";
import { AuthGuard } from "./core/services/auth-guard.service";
import { CompanyAuthGuard } from "./core/services/company-auth-guard.service";
import { Discharge317petitionComponent } from "./modules/admin/date-events/discharge/discharge317petition/discharge317petition.component";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";

export const rootRouterConfig: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./modules/admin/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Session" },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/admin/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Dashboard", breadcrumb: "DASHBOARD" },
      },
      {
        path: "case-details",
        loadChildren: () =>
          import("./modules/admin/case-details/case-details.module").then(
            (m) => m.CaseDetailsModule
          ),
        data: { title: "Case Details", breadcrumb: "Case Details" },
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./modules/admin/reports/hearing/hearing.module").then(
            (m) => m.HearingModule
          ),
        data: { title: "Hearing", breadcrumb: "Hearing" },
      },
      {
        path: "activity-log",
        loadChildren: () =>
          import("./modules/admin/activity-log/activity-log.module").then(
            (m) => m.ActivitylogModule
          ),
        data: { title: "Activity log", breadcrumb: "Activity log" },
      },
      {
        path: "change-password",
        loadChildren: () =>
          import("./modules/admin/change-password/change-password.module").then(
            (m) => m.ChangePasswordModule
          ),
        data: { title: "Change Password", breadcrumb: "Change Password" },
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./modules/admin/settings/settings.module").then(
            (m) => m.AppSettingsModule
          ),
        data: { title: "Settings", breadcrumb: "Settings" },
      },
      {
        path: "administrator",
        loadChildren: () =>
          import("./modules/admin/administrator/administrator.module").then(
            (m) => m.AppAdministratorModule
          ),
        data: { title: "Administrator", breadcrumb: "Administrator" },
      },
      {
        path: "complaints",
        loadChildren: () =>
          import("./modules/admin/complaints/complaints.module").then(
            (m) => m.ComplaintsModule
          ),
        data: { title: "Complaints", breadcrumb: "Complaints" },
      },
      {
        path: "bail-ab",
        loadChildren: () =>
          import("./modules/admin/bail-ab/bail-ab.module").then(
            (m) => m.BailABModule
          ),
        data: { title: "Bail/AB", breadcrumb: "Bail/AB" },
      },
      {
        path: "fir",
        loadChildren: () =>
          import("./modules/admin/fir/fir.module").then((m) => m.FIRModule),
        data: { title: "FIR", breadcrumb: "FIR" },
      },
      
      {
        path: "advocate-update",
        loadChildren: () =>
          import("./modules/admin/advocate-update/advocate-update.module").then((m) => m.AdvocateUpdateModule),
        data: { title: "Advocate Update", breadcrumb: "Advocate Update" },
      },
      
      {
        path: "charge-sheet",
        loadChildren: () =>
          import("./modules/admin/chargesheet/chargesheet.module").then(
            (m) => m.ChargeSheetModule
          ),
        data: { title: "ChargeSheet", breadcrumb: "Charge Sheet" },
      },
      {
        path: "high-court",
        loadChildren: () =>
          import("./modules/admin/all-cases/all-cases.module").then(
            (m) => m.AllCasesModule
          ),
        data: { title: "High Court", breadcrumb: "High Court" },
      },
      {
        path: "high-court-fir",
        loadChildren: () =>
          import("./modules/admin/all-cases/all-cases.module").then(
            (m) => m.AllCasesModule
          ),
        data: { title: "High Court", breadcrumb: "High Court" },
      },
      {
        path: "high-court-charge-sheet",
        loadChildren: () =>
          import("./modules/admin/all-cases/all-cases.module").then(
            (m) => m.AllCasesModule
          ),
        data: { title: "High Court", breadcrumb: "High Court" },
      },
      {
        path: "hearing-update",
        loadChildren: () =>
          import("./modules/admin/hearing-update/hearing-update.module").then(
            (m) => m.HearingUpdateModule
          ),
        data: { title: "Hearing Update", breadcrumb: "Hearing Update" },
      },
      {
        path: "317petition",
        loadChildren: () =>
          import("./modules/admin/petition317/petition317.module").then(
            (m) => m.Petition317Module
          ),
        data: { title: "317 Petition", breadcrumb: "317 Petition" },
      },
      {
        path: "case-daily-activity",
        loadChildren: () =>
          import(
            "./modules/admin/case-daily-activity/case-daily-activity.module"
          ).then((m) => m.CaseDailyActivityModule),
        data: {
          title: "Case Daily Activity",
          breadcrumb: "Case Daily Activity",
        },
      },
      {
        path: "civil-cases",
        loadChildren: () =>
          import("./modules/admin/pmla-civil/pmla-civil.module").then(
            (m) => m.PmlaCivilModule
          ),
        data: { title: "PMLA Civil", breadcrumb: "PMLA Civil" },
      },
      {
        path: "pmla-enforcement",
        loadChildren: () =>
          import(
            "./modules/admin/pmla-enforcement/pmla-enforcement.module"
          ).then((m) => m.PmlaEnforcementModule),
        data: {
          title: "PMLA Attachment Proceedings",
          breadcrumb: "PMLA Attachment Proceedings",
        },
      },
      {
        path: "pmla-appellate",
        loadChildren: () =>
          import("./modules/admin/pmla-appelate/pmla-appelate.module").then(
            (m) => m.PmlaAppelateModule
          ),
        data: {
          title: "PMLA Appellate Tribunal",
          breadcrumb: "PMLA Appellate Tribunal",
        },
      },
      {
        path: "pmla-criminal",
        loadChildren: () =>
          import(
            "./modules/admin/pmla-criminal-proceed/pmla-criminal-proceed.module"
          ).then((m) => m.PmlaCriminalProceedModule),
        data: {
          title: "PMLA Crinimal Proceed",
          breadcrumb: "PMLA Crinimal Proceed",
        },
      },
      {
        path: "pmla",
        loadChildren: () =>
          import("./modules/admin/pmla-criminal/pmla-criminal.module").then(
            (m) => m.PmlaCriminalModule
          ),
        data: { title: "PMLA", breadcrumb: "PMLA" },
      },
      {
        path: "private-complaint",
        loadChildren: () =>
          import(
            "./modules/admin/private-complaint/private-complaint.module"
          ).then((m) => m.PrivateComplaintModule),
        data: { title: "Private Complaint", breadcrumb: "Private Complaint" },
      },
      {
        path: "return-property",
        loadChildren: () =>
          import("./modules/admin/return-property/return-property.module").then(
            (m) => m.ReturnPropertyModule
          ),
        data: { title: "Return of Property", breadcrumb: "Return of Property" },
      },
      {
        path: "show-cause-notice",
        loadChildren: () =>
          import(
            "./modules/admin/show-cause-notice/show-cause-notice.module"
          ).then((m) => m.ShowCauseNoticeModule),
        data: { title: "Show Cause Notice", breadcrumb: "Show Cause Notice" },
      },
      {
        path: "department-proceedings",
        loadChildren: () =>
          import(
            "./modules/admin/department-proceedings/department-proceedings.module"
          ).then((m) => m.DepartmentProceedingsModule),
        data: { title: "Department Proceedings", breadcrumb: "Department Proceedings" },
      },
      {
        path: "discharge",
        loadChildren: () =>
          import("./modules/admin/discharge/discharge.module").then(
            (m) => m.DischargeModule
          ),
        data: { title: "Discharge", breadcrumb: "Discharge" },
      },
      {
        path: "seizure-mahazar",
        loadChildren: () =>
          import("./modules/admin/seizure-mahazar/seizure-mahazar.module").then(
            (m) => m.SeizureMahazarModule
          ),
        data: { title: "Seizure Mahazar", breadcrumb: "Seizure Mahazar" },
      },
      // {
      //   path: 'daily-update',
      //   loadChildren: () => import('./modules/admin/daily-update/daily-update.module').then(m => m.DailyUpdateModule),
      //   data: { title: 'Daily Update', breadcrumb: 'Daily Update' }
      // },
      {
        path: "daily-update/scn",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/show-cause-notice/show-cause-notice.module"
          ).then((m) => m.ShowCauseNoticeDateEventsModule),
        data: {
          title: "Date & Events Show Cause Notice",
          breadcrumb: "Date & Events Show Cause Notice",
        },
      },
      {
        path: "daily-update/department-proceedings",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/department-proceedings/department-proceeding.module"
          ).then((m) => m.DepartmentProceedingsDateEventsModule),
        data: {
          title: "Date & Events Department Proceedings",
          breadcrumb: "Date & Events Department Proceedings",
        },
      },
      {
        path: "daily-update/return-property",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/return-property/return-property.module"
          ).then((m) => m.ReturnPropertyDateEventsModule),
        data: {
          title: "Return of Property Date & Events",
          breadcrumb: "Return of Property Date & Events",
        },
      },
      {
        path: "daily-update/private-complaint",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/private-complaint/private-complaint.module"
          ).then((m) => m.PrivateComplaintDateEventsModule),
        data: {
          title: "Private Complaint Date & Events",
          breadcrumb: "Private Complaint Date & Events",
        },
      },
      {
        path: "daily-update/civil-cases",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/pmla-civil/pmla-civil.module"
          ).then((m) => m.PMLACivilDateEventsModule),
        data: {
          title: "Civil Cases Date & Events",
          breadcrumb: "Civil Cases Date & Events",
        },
      },
      {
        path: "daily-update/pmla-criminal",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/pmla-criminal/pmla-criminal.module"
          ).then((m) => m.PMLACriminalDateEventsModule),
        data: {
          title: "PMLA  Date & Events",
          breadcrumb: "PMLA  Date & Events",
        },
      },
      {
        path: "daily-update/pmla-without-cheif",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/pmla-without-cheif/pmla-without-cheif.module"
          ).then((m) => m.PmlaWithoutCheifDateEventsModule),
        data: {
          title: "PMLA Without Cheif",
          breadcrumb: "PMLA Without Cheif",
        },
      },
      {
        path: "daily-update/pmla-with-cheif",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/pmla-with-cheif/pmla-with-cheif.module"
          ).then((m) => m.PmlaWithCheifDateEventsModule),
        data: {
          title: "PMLA With Cheif",
          breadcrumb: "PMLA With Cheif",
        },
      },
      {
        path: "daily-update/pmla-appellate",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/pmla-appellate/pmla-appellate.module"
          ).then((m) => m.PMLAAppellateDateEventsModule),
        data: {
          title: "PMLA Appellate Tribunal  Date & Events",
          breadcrumb: "PMLA Appellate Tribunal  Date & Events",
        },
      },
      {
        path: "daily-update/bail-ab",
        loadChildren: () =>
          import("./modules/admin/date-events/bail-ab/bail-ab.module").then(
            (m) => m.BailABDateEventsModule
          ),
        data: {
          title: "Bail/AB Date & Events",
          breadcrumb: "Bail/AB Date & Events",
        },
      },
      {
        path: "daily-update/high-court",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/high-court/high-court.module"
          ).then((m) => m.HighCourtDateEventsModule),
        data: {
          title: "High Court Date & Events",
          breadcrumb: "High Court Date & Events",
        },
      },
      {
        path: "daily-update/special-court",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/special-court/special-court.module"
          ).then((m) => m.SpecialCourtDateEventsModule),
        data: {
          title: "Special Court Date & Events",
          breadcrumb: "Special Court Date & Events",
        },
      },
      {
        path: "daily-update/criminal-cases/jm-additional-court",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/jm-session-court/jm-session-court.module"
          ).then((m) => m.JMSessionCourtDateEventsModule),
        data: {
          title: "JM / Additional District Session Court Date & Events",
          breadcrumb: "JM / Additional District Session Court Date & Events",
        },
      },
      {
        path: "daily-update/criminal-cases/jm-court-cross",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/jm-court-cross/jm-court-cross.module"
          ).then((m) => m.JMCourtCrossDateEventsModule),
        data: {
          title: "JM Court Cross Examination Date & Events",
          breadcrumb: "JM Court Cross Examination Date & Events",
        },
      },
      {
        path: "daily-update/private-complaint/without-chief-cross",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/without-chief-cross/without-chief-cross.module"
          ).then((m) => m.WithoutchiefCrossModule),
        data: {
          title: "Without Chief and Cross Examination",
          breadcrumb: "Without Chief and Cross Examination Date & Events",
        },
      },
      {
        path: "daily-update/private-complaint/with-chief-cross",
        loadChildren: () =>
          import(
            "./modules/admin/date-events/with-chief-cross/with-chief-cross.module"
          ).then((m) => m.WithchiefCrossModule),
        data: {
          title: "With Chief and Cross Examination",
          breadcrumb: "With Chief and Cross Examination Date & Events",
        },
      },
      {
        path: "daily-update/discharge",
        loadChildren: () =>
          import("./modules/admin/date-events/discharge/discharge.module").then(
            (m) => m.DischargeDateEventsModule
          ),
        data: {
          title: "Discharge Date & Events",
          breadcrumb: "Discharge Date & Events",
        },
      },
      {
        path: "reports/daily-case-hearing",
        loadChildren: () =>
          import(
            "./modules/admin/reports/daily-case-hearing/daily-case-hearing.module"
          ).then((m) => m.DailyCaseHearingModule),
        data: {
          title: "Daily Case Hearing Report",
          breadcrumb: "Daily Case Hearing Report",
        },
      },

      {
        path: "reports/case-wise-report",
        loadChildren: () =>
          import(
            "./modules/admin/reports/case-wise-report/case-wise-report.module"
          ).then((m) => m.CaseWiseReportModule),
        data: { title: "Case Wise Report", breadcrumb: "Case Wise Report" },
      },
      {
        path: "reports/overall-case-list",
        loadChildren: () =>
          import(
            "./modules/admin/reports/overall-case-list/overall-case-list.module"
          ).then((m) => m.OverallCaseListModule),
        data: {
          title: "Overall Case List Report",
          breadcrumb: "Overall Case List Report",
        },
      },
      {
        path: "reports/case-stagewise",
        loadChildren: () =>
          import(
            "./modules/admin/reports/case-stagewise/case-stagewise.module"
          ).then((m) => m.CaseStagewiseModule),
        data: {
          title: "Overall Case List Report",
          breadcrumb: "Overall Case List Report",
        },
      },
      {
        path: "reports/court-report",
        loadChildren: () => 
          import(
            "./modules/admin/reports/court-report/court-report.module"
          ).then((m) => m.CourtReportModule)
      },
      {
        path: "reports/case-history",
        loadChildren: () =>
          import(
            "./modules/admin/reports/case-history/case-history.module"
          ).then((m) => m.CaseHistoryModule),
        data: {
          title: "Case History Report",
          breadcrumb: "Case History Report",
        },
      },
      {
        path: "reports/individual-hearing-report",
        loadChildren: () =>
          import(
            "./modules/admin/reports/individual-hearing-report/individual-hearing-report.module"
          ).then((m) => m.IndividualHearingReportModule),
        data: {
          title: "Individual Hearing Report",
          breadcrumb: "Individual Hearing Report",
        },
      },

      {
        path: "reports/abstract-report",
        loadChildren: () =>
          import(
            "./modules/admin/reports/abstract-report/abstract-report.module"
          ).then((m) => m.AbstractReportModule),
        data: { title: "Abstract Accused", breadcrumb: "Abstract Accused" },
      },

      {
        path: "reports/private-complaint",
        loadChildren: () =>
          import(
            "./modules/admin/reports/private-complaint/private-complaint.module"
          ).then((m) => m.PrivateComplaintModule),
        data: { title: "Private Complaint", breadcrumb: "Private Complaint" },
      },

      {
        path: "reports/apperance-report",
        loadChildren: () =>
          import(
            "./modules/admin/reports/appereance-report/apperance-report.module"
          ).then((m) => m.ApperanceReportModule),
        data: { title: "Apperance Report", breadcrumb: "Apperance Report" },
      },
      {
        path: "reports/abstract-witness",
        loadChildren: () =>
          import(
            "./modules/admin/reports/private-complaint-case-report/private-complaint-case-report.module"
          ).then((m) => m.PrivateComplaintCaseReportModule),
        data: { title: "Abstract Witness", breadcrumb: "Abstract Witness" },
      },
      {
        path: "reports/show-cause-notice",
        loadChildren: () =>
          import(
            "./modules/admin/reports/show-cause-notice/show-cause-notice.module"
          ).then((m) => m.ShowCauseNoticeModule),
        data: {
          title: "Show Cause Notice Report",
          breadcrumb: "Show Cause Notice Report",
        },
      },
      {
        path: "reports/witness-statement-report",
        loadChildren: () =>
          import(
            "./modules/admin/reports/witness-statement-report/witness-statement-report.module"
          ).then((m) => m.witnessStaementReportDateEventsModule),
        data: {
          title: "Date & Events Witness Statement Report",
          breadcrumb: "Date & Events Witness Statement Report",
        },
      },
    ],
  },

  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
