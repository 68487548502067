// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  
  production: false,
  // apiURL: 'https://dev.misreport.in/',
  // apiURL: 'http://52.66.59.115/',
  // apiURL: 'https://apimisreport.technogenesis.in/',
  apiURL: 'https://api.misreport.in/',
  //jar -cMf crm.technogenesis.info.zip dist

  // apiURL: 'http://192.168.1.88:8081/',

  // apiURL: 'http://localhost:8081/',
};
