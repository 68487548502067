import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-readmore',
  templateUrl: './readmore.component.html',
  styleUrls: ['./readmore.component.css']
})
export class ReadmoreComponent implements OnInit {
  @Input('content') content ! : string;
  @Input('limit') limit ! : number;
  @Input('completeWords') completeWords !: boolean;
original :any
  isContentToggled !: boolean;
  nonEditedContent !: string;
  constructor() { }

  ngOnInit(): void {
this.original = this.content
    this.nonEditedContent = this.content;
    this.content = this.formatContent(this.content);
  }


  toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    this.content = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.content);
  }

  formatContent(content: string) {
    if (this.completeWords) {
      this.limit = content.substring(0, this.limit).lastIndexOf(' ');
    }
    return  content?.length > this.limit ? `${content.substring(0, this.limit)}...` : content;
  }
}
