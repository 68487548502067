import { Component, OnInit, Input, OnDestroy, Renderer2, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from 'app/core/services/layout.service';
import { ThemeService } from 'app/core/services/theme.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { PORTALTYPE } from 'app/core/helpers/enum.helper';
import { UserService } from 'app/core/services/user.service';
import { PermissionService } from 'app/core/services/permission.service';
import { decodedToken } from 'app/core/helpers/token.helper';
import { get } from "lodash";
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html'
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  isSettingsRouteSub: Subscription
  tgssThemes: any[] = [];
  currentLang = 'en';
  availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]
  @Input() notificPanel;

  portalType: string = '';
  userType: string = '';
  queueCount = 0;
  checkType: any = PORTALTYPE;
  userName: any;
  name: any;
  isInSettingsMenu: boolean = false;
  settingsIndex: any = 0;
showEng = true;
showTam = false;
activeIndex: any;
  routerlink: string;

  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private userService: UserService,
    public permission: PermissionService,
    private router: Router

  ) {
    this.checkSettingsMenu();
    this.autoInit();
  }
  @HostListener('window:resize', ['$event'])
  ngOnInit() {
    let location = window.location.pathname.split('/');
    this.activeIndex = window.localStorage.getItem('settingsIndex');
    
    this.routerlink = window.localStorage.getItem('settingsrouter');
    
    if(location[1] == "settings" && (this.activeIndex == '' || this.activeIndex == undefined || this.activeIndex == null)){
      this.router.navigate(['/settings/court-info/court']);
    }
    else if(location[1] == "settings" && (this.activeIndex != '' || this.activeIndex != undefined || this.activeIndex != null) && (this.routerlink != '' || this.routerlink != undefined || this.routerlink != null)){
      this.router.navigate([`/settings/${this.routerlink}`]);
      
    }
    this.layoutConf = this.layout.layoutConf;
    this.tgssThemes = this.themeService.tgssThemes;
    this.menuItemSub = this.navService.menuItems$
      .subscribe(res => {
        console.log(res);
        
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        // let limit:any = 11;
        // let limit: any = window.matchMedia(`(max-width: 1372px)`).matches  ? 10 :  window.matchMedia(`(max-width: 1222px)`).matches ? 9 : window.matchMedia(`(max-width: 1142px)`).matches ? 6 : '11';
        let limit: any
        if (window.matchMedia(`(max-width: 1251px) and (min-width: 1100px)`).matches) {
          limit = 5;
        } else if (window.matchMedia(`(max-width: 1099px)`).matches) {
          limit = 4;
        } else if (window.matchMedia(`(max-width: 1281px)`).matches) {
          limit = 6;
        } else if (window.matchMedia(`(max-width: 1367px) and (min-width: 1282px)`).matches) {
          limit = 6;
        } else if ( window.matchMedia(`(max-width: 1536px) and (min-width: 1367px)`).matches) {
          limit = 6;
        } else if ( window.matchMedia(`(min-width: 1600px)`).matches)  {
          limit = 10;
        }
        let mainItems: any[] = res.slice(0, limit)
        console.log(mainItems);
        

        if (res.length <= limit) {
          return this.menuItems = mainItems;
        }

        let subItems: any[] = res.slice(limit, res.length)
        
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems;
      })
  }

  route(link){
    console.log(link);
    
    this.router.navigate([`/${link}/court-info/court`]);
    // window.localStorage.setItem('settingsIndex', '0'); 
  }

  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
    this.isSettingsRouteSub.unsubscribe();
  }
  eng() {
    this.showEng = true;
    this.showTam = false;
  }
  tam() {
    this.showTam = true;
    this.showEng = false;
  }
  setLang() {
    this.translate.use(this.currentLang)
  }
  changeTheme(theme) {
    this.layout.publishLayoutChange({ matTheme: theme.name })
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }
  isMd() {
    return window.matchMedia(`(max-width: 1372px)`).matches;
  }
  is() {
    return window.matchMedia(`(max-width: 1222px)`).matches;
  }



  autoInit() {
    
    const token = decodedToken();

    this.portalType = get(token, 'portal') ?? '';
    this.userType = get(token, 'user_type') ?? '';
    this.userName = get(token, 'name');
    this.name = get(token, 'name');
  }




  logout(portalType) {
    // this.messagingService.deleteToken();
    // this.messagingService.sendFCMToken(null);
    if (portalType == PORTALTYPE.ADMIN) {
      this.userService.logout();
    } else if (portalType == PORTALTYPE.COMPANY) {
      this.userService.logout();
    } else {
      this.userService.logout();
    }
  }


  getLink(item) {
    // console.log(item, "state");
    // console.log(item.sub[0].state, "13421343434343434343434343434");
    if (item?.name === "Settings") {
      if (item?.sub) {
        console.log(item?.name);
        
        return this.isInSettingsMenu == true ? item?.sub[this.settingsIndex]?.state : item?.sub[0]?.state;
      } else return item?.state
    } else {
      return item?.state
    }
  }




  checkSettingsMenu() {
    this.isSettingsRouteSub = this.userService.isInSettingsMenu.subscribe((res: any) => {
      if (res) {

        this.isInSettingsMenu = res.isIn ?? false;
        this.settingsIndex = res?.index ?? 0;
      }
    })

  }



}
