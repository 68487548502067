import { IMenuItem } from "./navigation.model";

export let MISMenu: IMenuItem[] = [
  {
    module: ["dashboard"],
    name: "DASHBOARD",
    type: "link",
    state: "dashboard",
    icon: "assets/images/dashboard.png",
  },
  {
    module: ["chargeSheet", "complaint", "fir", "bail", "discharge", "high court", "Seizure Mahazer","returnProperty"],
    name: "Criminal Cases",
    type: "dropDown",
    state: "",
    icon: "assets/images/w-criminal.png",
    sub: [
      {
        module: ["complaint"],
        name: "Complaint",
        type: "link",
        state: "complaints",
      },
      {
        module: ["fir"],
        name: "FIR",
        type: "link",
        state: "fir",
      },
      {
        module: ["bail"],
        name: "Bail/AB",
        type: "link",
        state: "bail-ab",
      },
      {
        module: ["high court"],
        name: "FIR Quash",
        type: "link",
        state: "high-court-fir",
      },
      {
        module: ["discharge"],
        name: "Discharge",
        type: "link",
        state: "discharge",
      },
      {
        module: ["Seizure Mahazer"],
        name: "Seizure Mahazar",
        type: "link",
        state: "seizure-mahazar",
      },
      {
        module: ["returnProperty"],
        name: "Return of Property",
        type: "link",
        state: "return-property",
      },
      {
        module: ["high court"],
        name: "Charge Sheet Quash",
        type: "link",
        state: "high-court-charge-sheet",
      },
      {
        module: ["chargeSheet"],
        name: "Charge Sheet",
        type: "link",
        state: "charge-sheet",
      },
    ],
  },

  {
    module: ["pmla Pro", "pmlaCriminalProceedings", "pmla appellate"],
    name: "PMLA",
    type: "dropDown",
    state: "",
    icon: "assets/images/w-pmla.png",
    sub: [
      {
        module: ["pmla Pro"],
        name: "PMLA Attachment Proceedings",
        type: "link",
        state: "pmla-enforcement",
      },
      {
        module: ["pmlaCriminalProceedings"],
        name: "PMLA Criminal Proceedings",
        type: "link",
        state: "pmla-criminal",
      },
      {
        module: ["pmla appellate"],
        name: "PMLA Appellate Tribunal",
        type: "link",
        state: "pmla-appellate",
      },
    ],
  },
  {
    module: ["civil cases"],
    name: "Civil Cases",
    type: "link",
    state: "civil-cases",
    icon: "assets/images/w-civil.png",
  },
  {
    module: ["showCauseNotice"],
    name: "Show Cause Notice",
    type: "link",
    state: "show-cause-notice",
    icon: "assets/images/w-daily.png",
  },

  // {
  //   name: "Cases",
  //   type: "dropDown",
  //   state: "",
  //   sub: [
  //     {
  //       name: "Case Daily Activities",
  //       type: "link",
  //       state: "case-daily-activity",
  //     },
  //   ],
  // },

  {
    name: "Department Proceedings",
    type: "link",
    state: "department-proceedings",
    icon: "assets/images/w-dept.png",
  },
  {
    module: ["privateComplaint"],
    name: "Private Complaint",
    type: "link",
    state: "private-complaint",
    icon: "assets/images/w-private.png",
  },
  {
    module: ["highCourt DailyUpdate", "bailDailyUpdate", "jm additional", "jm cross", "dischargeDailyUpdate",
      "showCauseNotice DailyUpdate", "returnPropertyDailyUpdate", "civilCases DailyUpdate", "pmla without",
      "pmla with", "pmlaAppellate DailyUpdate", "pc with","pc without",],
    name: "Daily Update",
    type: "dropDown",
    state: "",
    icon: "assets/images/w-daily.png",
    sub: [
      {
        module: ["highCourt DailyUpdate"],
        name: "High Court Hearing",
        type: "link",
        state: "daily-update/high-court",
      },
      {
        module: ["bailDailyUpdate"],
        name: "Bail/AB",
        type: "link",
        state: "daily-update/bail-ab",
      },
      {
        module: ["jm additional","jm cross"],
        name: "Criminal Cases",
        type: "dropDown",
        state: "daily-update/criminal-cases",
        sub: [
          {
            module: ["jm additional"],
            name: "JM / Additional District Sessions Court",
            type: "link",
            state: "jm-additional-court",
          },
          {
            module: ["jm cross"],
            name: "JM Court Cross Examination",
            type: "link",
            state: "jm-court-cross",
          },
        ],
      },
      {
        module: ["dischargeDailyUpdate"],
        name: "Discharge",
        type: "link",
        state: "daily-update/discharge",
      },
      {
        module: ["showCauseNotice DailyUpdate"],
        name: "Show Cause Notice",
        type: "link",
        state: "daily-update/scn",
      },
      {
        module: ["returnPropertyDailyUpdate"],
        name: "Return of Property",
        type: "link",
        state: "daily-update/return-property",
      },
      {
        module: ["civilCases DailyUpdate"],
        name: "Civil Cases",
        type: "link",
        state: "daily-update/civil-cases",
      },
      // {
      //   name: "PMLA Attachment Proceedings",
      //   type: "link",
      //   state: "daily-update/pmla-attachment-proceeding",
      // },
      {
        module: ["pmla without", "pmla with"],
        name: "PMLA Criminal Proceedings",
        type: "dropDown",
        state: "",
        sub: [
          {
            module: ["pmla without"],
            name: "PMLA Without Chief and Cross Examination",
            type: "link",
            state: "daily-update/pmla-without-cheif",
          },
          {
            module: ["pmla with"],
            name: "PMLA With Chief and Cross Examination",
            type: "link",
            state: "daily-update/pmla-with-cheif",
          },
        ],
      },
      {
        module: ["pmlaAppellate DailyUpdate"],
        name: "PMLA Appellate Tribunal",
        type: "link",
        state: "daily-update/pmla-appellate",
      },
      {
        module: ["pc with", "pc without"],
        name: "Private Complaint",
        type: "dropDown",
        state: "daily-update/private-complaint",
        sub: [
          {
            module: ["pc without"],
            name: "Without Chief and Cross Examination",
            type: "link",
            state: "without-chief-cross",
          },
          {
            module: ["pc with"],
            name: "With Chief and Cross Examination",
            type: "link",
            state: "with-chief-cross",
          },
        ],
      },
      {
        module: ["departmentProceeding"],
        name: "Department Proceedings",
        type: "link",
        state: "daily-update/department-proceedings",
      },

      // {
      //   name: "case",
      //   type: "link",
      //   state: "daily-update/case",
      // },
      // {
      //   name: "Hearing",
      //   type: "link",
      //   state: "daily-update/case",
      // },

      // {
      //   name: "FIR Quash",
      //   type: "link",
      //   state: "daily-update/fir-quash",
      // },

      // {
      //   name: "Return of Property",
      //   type: "link",
      //   state: "daily-update/return-property",
      // },

      // {
      //   name: "PMLA",
      //   type: "link",
      //   state: "daily-update/pmla-criminal",
      // },
    ],
  },
  {
    module: ["high court"],
    name: "High Court",
    type: "link",
    state: "high-court",
    icon: "assets/images/w-daily.png"
  },

  {
    name: "Reports",
    type: "dropDown",
    state: "reports",
    icon: "assets/images/w-daily.png",
    module: ["dailyCaseHearingReport", "caseStageWiseReport",
      "appearanceReport", "caseHistoryReport",
      "individualHearingReport", "abstractAccusedReport",
      "abstractWitnessReport",
      "showCauseNoticeReport",
      "caseWiseReport","witnessStaementReport", "courtReport"
    ],

    sub: [
      {
        name: "Daily Case Hearing",
        type: "link",
        state: "daily-case-hearing",
        module: ['dailyCaseHearingReport'],

      },
      {
        name: "Case Stage Wise",
        type: "link",
        state: "case-stagewise",
        module: ['caseStageWiseReport']
      },
      {
        name: "Case History",
        type: "link",
        state: "case-history",
        module: ['caseHistoryReport']
      },
      {
        name: "Apperance Report",
        type: "link",
        state: "apperance-report",
        module: ['appearanceReport']
      },
      {
        name: "Individual Hearing Report",
        type: "link",
        state: "individual-hearing-report",
        module: ['individualHearingReport']
      },
      {
        name: "Abstract Accused",
        type: "link",
        state: "abstract-report",
        module: ['abstractAccusedReport']
      },
      {
        name: "Abstract Witness",
        type: "link",
        state: "abstract-witness",
        module: ['abstractWitnessReport']
      },
      {
        name: "Show Cause Notice",
        type: "link",
        state: "show-cause-notice",
        module: ['showCauseNoticeReport']
      },
      {
        name: "Witness Statement Report(Criminal Case, Private Complaint)",
        type: "link",
        state: "witness-statement-report",
        module: ["witnessStaementReport"],
      },
      {
        name: "Court Type Wise Report",
        type: "link",
        state: "court-report",
        module: ["courtReport"],
      },
      // {
      //   name: "Private Complaint",
      //   type: "link",
      //   state: "private-complaint",
      // },
    ],
  },
  // {
  //   name: "Case",
  //   type: "dropDown",
  //   state: "",
  //   sub: [
  //     {
  //       name: "Case Details",
  //       type: "link",
  //       state: "case-details",
  //     },
  //     {
  //       name: "Hearing Details",
  //       type: "link",
  //       state: "complaints",
  //     },
  //   ],
  // },

  // {
  //   name: "Activity Logs",
  //   type: "link",
  //   state: "activity-log"
  // },
  // {
  //   name: "Reports",
  //   type: "dropDown",
  //   state: "reports",
  //   sub: [
  //     {
  //       name: "Case",
  //       type: "link",
  //       state: "complaints",
  //     },
  //     {
  //       name: "Hearing",
  //       type: "link",
  //       state: "hearing",
  //     },
  //   ],
  // },
  {
    name: "Master",
    type: "dropDown",
    module: [
      "court",
      "judge",
      "Department proceedings",
      "case type",
      "case stage",
      "type of case",
      "case number",
      "designation",
      "location",
      "appearing",
      "ActSection",
      "investigating officer",
      "advocate",
      "referance type",
      "show cause no",
      "reference of occurance",
      "village of occurance",
      "property",
      "police station",
      "number",
      "result",
      "complaintant",
      "section",
      "propertyType",
      "rule",
      "rules-rule",
      "role",
      "user",
      "module",
      "department",
      "referenceNumber",
      ""
    ],
    state: "",
    sub: [
      {
        name: "General Settings",
        type: "link",
        module: [
          "court",
          "judge",
          "Department proceedings",
          "case type",
          "case stage",
          "type of case",
          "case number",
          "designation",
          "location",
          "appearing",
          "ActSection",
          "investigating officer",
          "advocate",
          "referance type",
          "show cause no",
          "reference of occurance",
          "village of occurance",
          "location reference",
          "property",
          "police station",
          "number",
          "result",
          "complaintant",
          "section",
          "propertyType",
          "rule",
          "rules-rule",
          "module",
          "department",
          "referenceNumber"
        ],
        state: "settings",
        sub: [
          {
            name: "Court",
            type: "link",
            module: ["court"],
            state: "court-info/court",
          },
          {
            name: "Judges",
            type: "link",
            module: ["judge"],
            state: "court-info/judges",
          },
          {
            name: "module",
            type: "link",
            module: ["module"],
            state: "department-proceedings/modulename",
          },
          {
            name: "department",
            type: "link",
            module: ["department"],
            state: "department-proceedings/department",
          },
          {
            name: "referenceNumber",
            type: "link",
            module: ["referenceNumber"],
            state: "department-proceedings/refno",
          },
          {
            name: "Case Type",
            type: "link",
            module: ["casetype"],
            state: "case-info/case-type",
          },
          {
            name: "Case Number",
            type: "link",
            module: ["case number"],
            state: "case-info/case-no",
          },
          {
            name: "Case Stage",
            type: "link",
            module: ["casestage"],
            state: "case-info/case-stage",
          },
          {
            name: "Type Of Case",
            type: "link",
            module: ["typeofcase"],
            state: "case-info/type-of-case",
          },
          {
            name: "Designation",
            type: "link",
            module: ["designation"],
            state: "designation",
          },
          {
            name: "Location",
            type: "link",
            module: ["location"],
            state: "location",
          },
          {
            name: "Appearing Info",
            type: "link",
            module: ["appearing"],
            state: "appearing-info",
          },
          {
            name: "Act Sec",
            type: "link",
            module: ["ActSection"],
            state: "act-sec",
          },
          {
            name: "Investigating Officer",
            type: "link",
            module: ["investigating officer"],
            state: "investigating-officer",
          },
          {
            name: "Advocate Info",
            type: "link",
            module: ["advocate"],
            state: "advocate-info",
          },
          {
            name: "Reference Type",
            type: "link",
            module: ["referance type"],
            state: "reference/type",
          },
          {
            name: "Show Case No",
            type: "link",
            module: ["show cause no"],
            state: "reference/showcaseno",
          },
          {
            name: "Occurrence",
            type: "link",
            module: ["reference of occurance"],
            state: "reference/occurance",
          },
          {
            name: "Village Of Occurrence",
            type: "link",
            module: ["villageofoccurance"],
            state: "reference/villageofoccurance",
          },
          {
            name: "Location Reference",
            type: "link",
            module: ["location reference"],
            state: "reference/location",
          },
          {
            name: "Property",
            type: "link",
            module: ["property"],
            state: "property",
          },
          {
            name: "Police Station",
            type: "link",
            module: ["police station"],
            state: "police-station",
          },
          {
            name: "Numbers",
            type: "link",
            module: ["number"],
            state: "numbers",
          },
          {
            name: "Result",
            type: "link",
            module: ["result"],
            state: "result",
          },
          {
            name: "Complainant",
            type: "link",
            module: ["complaintant"],
            state: "complaintant",
          },
          {
            name: "Section",
            type: "link",
            module: ["section"],
            state: "section",
          },
          {
            name: "Type of Property",
            type: "link",
            module: ["propertyType"],
            state: "property-type",
          },
          {
            name: "Rule",
            type: "link",
            module: ["rule"],
            state: "rule",
          },
          {
            name: "Rules & Rule",
            type: "link",
            module: ["rulesAndRule"],
            state: "rules-rule",
          },
        ],
      },
      {
        name: "Administration",
        type: "dropDown",
        module: ["role", "user"],
        state: "administrator",
        sub: [
          {
            name: "Roles",
            type: "link",
            module: ["role"],
            state: "role",
          },
          {
            name: "Users",
            type: "link",
            module: ["user"],
            state: "users",
          },
          {
            name: "Roles & Permissions",
            type: "link",
            state: "role-permission",
          },
        ],
      },
      {
        name: "Activity Log",
        type: "link",
        state: "activity-log",
      },
    ],
  },
  {
    name: "317 Petition",
    type: "link",
    state: "317petition",
  },
];
