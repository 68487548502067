<mat-card class="p-0">
    <mat-card-title class="">
        <div class="card-title-text">Crop Image</div>
        <mat-divider></mat-divider>
    </mat-card-title>
    <mat-card-content class="mb-0 pb-0">
        <div fxLayout="row wrap" class="main_details mt-16">
            <div fxFlex="100" fxFlex.gt-xs="100" class="">
                <!-- CROPPER WRAPPER -->
                <div class="cropper-wrapper">
 
                    <!-- LOADING -->
                    <div class="loading-block" *ngIf="isLoading">
                        <div class="spinner"></div>
                    </div>

                    <!-- LOAD ERROR -->
                    <div class="alert alert-warning" *ngIf="loadError">{{ loadImageErrorText }}</div>

                    <!-- CROPPER -->
                    <div class="cropper">
                        <img #image alt="image" [src]="imageUrl" (load)="imageLoaded($event)" (error)="imageLoadError($event)" />
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>

    <mat-card-footer>
        <button mat-raised-button color="primary" class="btn-primary-bg mr-12 create-btn" type="button" (click)="crop()">
      CROP
    </button>

    <button mat-stroked-button class="filter-btn mr-8 create-btn" color="primary" 
    (click)="discard()">
        Discard
      </button>




        <!-- <a class="pl-8 primary-txt-color" (click)="discard()">DISCARD</a> -->
    </mat-card-footer>

</mat-card>