import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppLoaderComponent } from 'app/shared/components/app-loader/app-loader.component';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  dialogRef: MatDialogRef<AppLoaderComponent>;
  constructor(private dialog: MatDialog,
    private userService: UserService

  ) { }

  public open(title: string = ''): Observable<boolean> {
    // this.userService.isScrollToTop.next(true);
    this.dialogRef = this.dialog.open(AppLoaderComponent, { disableClose: true, backdropClass: 'light-backdrop', panelClass: 'loader-custom-class' });
    // this.dialogRef.updateSize('200px');
    this.dialogRef.componentInstance.title = title;
    return this.dialogRef.afterClosed();
  }

  public close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
