<!-- <div class="text-center">
    <h6 class="m-8 p-4">{{ title }}</h6>
    <div mat-dialog-content [style.width]="'550'">
        <mat-spinner [style.margin]="'auto'" [style.overflow-x]="'hidden'"></mat-spinner>
    </div>
</div> -->

<div fxLayout="row wrap" class="row mt-16">
    <div fxFlex="100" fxFlex.gt-xs="100" class="text-center">
        <img src="assets/images/loader.gif" width="150" height="150" alt="logo" class="" />
    </div>
</div>