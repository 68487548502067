import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})

export class GenerateRouteService {


    constructor(private route: Router) {

    }

    public queueCount = new ReplaySubject<any>(0);

    /** queue count */

    setQueueCount(property: any, storeProp: boolean = false) {
        if (storeProp) {
            localStorage.setItem('queueCount', JSON.stringify(property));
        }
        this.queueCount.next(property);
    }

    getQueueCount() {
        return this.queueCount;
    }

    // generateRoutePath(portalName, page, idObj) {
    //     let splitPath = page.split('_');
    //     let givenPathName = splitPath.pop();
    //     let givenModule = splitPath.join('-');
    //     let getPortalModules = routeModules[portalName];
    //     let { actualModule, path } = this.filterByModule(getPortalModules, givenModule);
    //     let pathName = this.filterByPath(path, givenPathName, idObj);
    //     portalName = portalName == 'teacher' ? '/' + portalName : portalName;
    //     return `${portalName}/${actualModule}/${pathName}`;
    // }

    // filterByModule(getPortalModules, moduleName) {
    //     return getPortalModules.find(el => el.givenModule == moduleName);
    // }

    // filterByPath(getPath, pathName, idObj) {
    //     let filteredPath = getPath.find(el => el.givenPath == pathName);
    //     return this.filterQueryParams(filteredPath, idObj);
    // }

    // filterQueryParams(params, idObj) {
    //     let pathQuery;
    //     if (params.isMultipleIdRequired == true) {
    //         let length = params.givenMulipleIds.length;
    //         if (length > 1) {
    //             pathQuery = params.givenMulipleIds.reduce((prev, curr) => String(idObj[prev]) + '/' + String(idObj[curr]))
    //         } else {
    //             pathQuery = idObj[params.givenMulipleIds[0]];
    //         }
    //     } else if (params.isSingleIdRequired == true) {
    //         pathQuery = params.actualPath + '/' + idObj[params.givensingleIdName];
    //     } else {
    //         pathQuery = params.actualPath;
    //     }
    //     return pathQuery; 
    // }

    navigateRouteForPhysio(portalName, page, idObj) {
        this.route.navigate([`/physiotherapist/info/list`])
    }

    navigateRouteForDietitian(portalName, page, idObj) {
        this.route.navigate([`/dietitian/info/list`])
    }

    navigateRouteForGym(portalName, page, idObj) {
        if (page == 'member_list') {
            this.route.navigate(["/members/list"])
        } else if (page == 'dietician_consult') {
            this.route.navigate(["/consultation/dietitian-list"])
        } else if (page == 'physio_consult') {
            this.route.navigate(["consultation/list"]);
        } else if (page == 'diet_consult') {
            this.route.navigate(["/consultation/dietitian-list"])
        }
    }


    array = { "key": "id", "value": "[2]", "description": "" }
}