
import { Injectable } from '@angular/core';
import { IChildItem, IMenuItem } from "../models/navigation.model";
import { PORTALTYPE } from "../helpers/enum.helper";
import { decodedToken } from '../helpers/token.helper';
import { get } from "lodash";
import { MISMenu } from '../models';

@Injectable({
    providedIn: 'root'
})

export class PermissionService {

    menuSortList: IMenuItem[] = [];
    permissions: Object;
    roleId: number = 0;
    portalType: string = '';

    constructor() {

    }

    hasPermission(moduleList: Array<any>, actionList?: Array<any>): boolean {
        this.autoInit();
        // For Parent Menu
        // if (this.portalType === PORTALTYPE.GYM) {
        //     return true;
        // }

        if (this.roleId <= 0) {
            return false;
        }

        if (this.roleId == 1) {
            return true;
        }

        if (!this.permissions) {
            return false;
        }

        if (!actionList) {
            return this.isModuleHasAction(this.permissions, moduleList);
        }

        if (actionList?.length > 0) {
            return this.isActionFound(this.permissions, moduleList, actionList);
        }

        return false;
    }

    isModuleHasAction(permList: Object, moduleList: Array<any>): boolean {
        let admits = moduleList.filter(value => {
            if (permList.hasOwnProperty(value)) {
                return this.getValueByKey(permList, value).length > 0;
            }
        });
        return admits.length > 0;
    }

    isActionFound(permList: Object, moduleList: Array<any>, searchList: Array<any>): boolean {
        let admits = moduleList.filter(value => {
            if (permList.hasOwnProperty(value)) {
                let moduleActions = this.getValueByKey(permList, value);
                let permittedAction = moduleActions.filter(maction => {
                    return searchList.indexOf(maction) !== -1;
                });
                return permittedAction.length > 0;
            }
        });
        return admits.length > 0;
    }

    init(roleId: number, portalType: string, permissions: Object) {
        this.roleId = roleId;
        this.portalType = portalType;
        this.permissions = Object.keys(permissions).length > 0 ? permissions : {};
    }

    autoInit() {
        const token = decodedToken();
        this.portalType = get(token, 'portal') ?? '';
        this.roleId = get(token, 'acl_role_id') ?? '';
        this.permissions = get(token, 'permission') ?? {};
    }

    // get authMenu(): IMenuItem[] {
    //     if ((this.roleId == 3 && this.portalType == PORTALTYPE.GYM) || this.portalType == PORTALTYPE.DIETITIAN || this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
    //         let menu: IMenuItem[] = [];
    //         this.menuSortList = this.menu;
    //         menu = this.menuSortList.filter(data => {
    //             return (data.module?.length > 0 && this.hasPermission(data.module));
    //         });
    //         return menu.map(x => {
    //             if (x?.sub) { x.sub = this.getAuthSubmMenu(x.sub); }
    //             return x;
    //         });
    //     }
    // }


    get authMenu(): IMenuItem[] {
        const token = decodedToken();
        if ((this.roleId == 1 && this.portalType == PORTALTYPE.ADMIN) || this.portalType == PORTALTYPE.COMPANY) {
            return this.menu;
        }
        let menu: IMenuItem[] = [];
        this.menuSortList = this.menu;
        menu = this.menuSortList.filter(data => {
            return (data.module?.length > 0 && this.hasPermission(data.module));
        });
        return menu.map(x => {
            if (x?.sub) { x.sub = this.getAuthSubmMenu(x.sub); }
            return x;
        });
    }

    getAuthSubmMenu(ichild: IChildItem[]): IChildItem[] {
        let childMenu: IChildItem[] = [];
        childMenu = ichild.filter((data) => {
            return data.module?.length > 0 && this.hasPermission(data.module);
        });
        return childMenu.map((x) => {
            if (x?.sub) {
                x.sub = this.getAuthChildSubMenu(x.sub);
            }
            return x;
        });
    }

    getAuthChildSubMenu(ichild: IChildItem[]): IChildItem[] {
        return ichild.filter((data) => {
            return data.module?.length > 0 && this.hasPermission(data.module);
        });
    }

    get menu(): IMenuItem[] {
        let menuList: IMenuItem[] = [];
        if (this.portalType == PORTALTYPE.ADMIN) {
            menuList = MISMenu;
        }
        else {
            menuList = MISMenu;
        }
        return menuList;
    }

    getValueByKey(object: Object, searchKey: string): Array<any> {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                if (key === searchKey) {
                    return object[key] as Array<any>;
                }
            }
        }

        return [];
    }


    has(moduleList: Array<any>, actionList?: Array<any>): boolean {
        this.autoInit();
        // For Parent Menu
        // if (this.portalType === PORTALTYPE.GYM) {
        //     return true;
        // }

        if (this.roleId <= 0) {
            return false;
        }

        if (this.roleId == 1) {
            return true;
        }

        if (!this.permissions) {
            return false;
        }

        if (!actionList) {
            return this.isModuleHasAction(this.permissions, moduleList);
        }

        if (actionList?.length > 0) {
            return this.isActionFound(this.permissions, moduleList, actionList);
        }

        return false;
    }




    getRouterLink(): String {
        const self = this
        function has(modulename): boolean {
            return self.hasPermission([modulename])
        }
        switch (true) {
            case has('dashboard'): return '/dashboard';
            // Criminal Cases
            case has('complaint'): return '/complaints';
            case has('fir'): return '/fir';
            case has('bail'): return '/bail-ab';
            case has('high court'): return '/high-court-fir';
            case has('discharge'): return '/discharge';
            case has('Seizure Mahazer'): return '/seizure-mahazar';
            case has('returnProperty'): return '/return-property';
            case has('high court'): return '/high-court-charge-sheet';
            case has('chargeSheet'): return '/charge-sheet';
            // PMLA
            case has('pmla Pro'): return '/pmla-enforcement';
            case has('pmlaCriminalProceedings'): return '/pmla-criminal';
            case has('pmla appellate'): return '/pmla-appellate';
            // Civil Cases
            case has('civil cases'): return '/civil-cases';
            // Show cause Notice
            case has('show cause no'): return '/show-cause-notice';
            // Private Complaint
            case has('privateComplaint'): return '/private-complaint';
            // Daily Update
            case has('highCourt DailyUpdate'): return '/daily-update/high-court';
            case has('bailDailyUpdate'): return '/daily-update/bail-ab';
            case has('jm additional'): return '/daily-update/criminal-cases/jm-additional-court';
            case has('jm cross'): return '/daily-update/criminal-cases/jm-court-cross';
            case has('dischargeDailyUpdate'): return '/daily-update/discharge';
            case has('showCauseNotice DailyUpdate'): return '/daily-update/scn';
            case has('returnPropertyDailyUpdate'): return '/daily-update/return-property';
            case has('civilCases DailyUpdate'): return '/daily-update/civil-cases';
            case has('pmla without'): return '/daily-update/pmla-without-cheif';
            case has('pmla with'): return '/daily-update/pmla-with-cheif';
            case has('pmlaAppellate DailyUpdate'): return '/daily-update/pmla-appellate';
            // case has('pmlaPro DailyUpdate'): return '/pmlaPro DailyUpdate';
            case has('pc without'): return '/daily-update/private-complaint/without-chief-cross';
            case has('pc with'): return '/daily-update/private-complaint/with-chief-cross';
            case has('departmentProceeding'): return '/daily-update/department-proceedings';
            // High Court
            case has('high court'): return '/high-court';
            // Reports
            case has('dailyCaseHearingReport'): return '/reports/daily-case-hearing';
            case has('caseStageWiseReport'): return '/reports/case-stagewise';
            case has('courtReport'): return '/reports/court-report';
            case has('caseHistoryReport'): return '/reports/case-history';
            case has('appearanceReport'): return '/reports/apperance-report';
            case has('individualHearingReport'): return '/reports/individual-hearing-report';
            case has('abstractAccusedReport'): return '/reports/abstract-report';
            case has('abstractWitnessReport'): return '/reports/abstract-witness';
            case has('showCauseNoticeReport'): return '/reports/show-cause-notice';
            case has('witnessStaementReport'): return '/reports/witness-statement-report';
            // case has('caseWiseReport'): return '/caseWiseReport';

            // Master General Settings
            case has('court'): return '/settings/court-info/court';
            case has('judge'): return '/settings/court-info/judges';
            case has('case number'): return '/settings/case-info/case-no';
            case has('case type'): return '/settings/case-info/case-type';
            case has('case stage'): return '/settings/case-info/case-stage';
            case has('type of case'): return '/settings/case-info/type-of-case';
            case has('number'): return '/settings/case-info/case-no';
            case has('designation'): return '/settings/designation';
            case has('location'): return '/settings/location';
            case has('complaintant'): return '/settings/complaintant';
            case has('appearing'): return '/settings/appearing-info';
            case has('advocate'): return '/settings/advocate-info';
            case has('ActSection'): return '/settings/act';
            case has('section'): return '/settings/sections';
            case has('investigating officer'): return '/settings/investigating-officer';

            case has('referance type'): return '/settings/reference/type';
            case has('show cause no'): return 'settings/reference/showcauseno'
            case has('occurance'): return '/settings/reference/occurance';
            case has('reference of occurance'): return '/settings/reference/occurance';
            case has('village of occurance'): return '/settings/reference/villageofoccurance';
            case has('Location Reference'): return '/settings/reference/location';
            case has('propertyType'): return '/settings/property-type';
            case has('property'): return '/settings/property';
            case has('Survey Number'): return '/settings/survey-no';

            case has('module'): return '/settings/department-proceedings/modulename';
            case has('department'): return '/settings/department-proceedings/department';
            case has('referenceNumber'): return '/settings/department-proceedings/refno';
            case has('police station'): return '/settings/police-station';
            case has('result'): return '/settings/result';
            // Administrator
            case has('role'): return '/administrator/role';
            case has('user'): return '/administrator/users';
            case has('user'): return '/administrator/role-permission';
            default: return ''
            // case has('Complaint Receiver'): return '/Complaint Receiver';
            // case has('showCauseNotice'): return '/showCauseNotice';
            // case has('magazar'): return '/magazar';
        }

    }
}







